.s-formats {
  --bgSecondary: var(--surfaceNeutralD2Default);
  --iconPrimary: var(--iconNeutralForceLight);
  --borderPrimary: var(--borderNeutralSubtlest);
  --red: var(--textAccentPrimaryDefault);
}

.s-formats__title {
  margin-bottom: 1.25rem;
  font-size: 3.5rem;
  line-height: 4rem;
  letter-spacing: -0.03em;
}

.s-formats__wrapper--btn-text {
  margin-top: 1.5rem;
  margin-bottom: 8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 6rem;
}

.s-formats__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 6rem;
  width: 6rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0px;
}

.s-formats__text {
  max-width: 32rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: 0.03em;
  opacity: 0.72;
}

.s-formats__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(0, 1fr));
  grid-template-columns: repeat(4, minmax(0, 1fr));
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1.25rem;
  padding-bottom: 0.25rem;
}

.s-formats__list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.s-formats__list::-webkit-scrollbar-track {
  background-color: transparent;
}

.s-formats__list::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
  opacity: 0.4;
}

.s-formats__list::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.s-formats__item {
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
  -webkit-transition-property: all !important;
  transition-property: all !important;
}

.s-formats__item__link {
  display: inline-block;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
}

.s-formats__item-image {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100%;
}

.s-formats__item-image img {
  position: absolute;
  inset: 0px;
  height: 100%;
  width: 100%;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.s-formats__item-image--visible {
  opacity: 1;
}

.s-formats__item-image--hover {
  opacity: 0;
}

.s-formats__item-wrapper-text-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 9rem;
  width: 100%;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.s-formats__item-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  font-family: InterMedium;
  line-height: 1.5rem;
  opacity: 0.72;
  letter-spacing: 0.00625em;
}

.s-formats__item-icon {
  visibility: hidden;
  border-radius: 9999px;
  background-color: var(--red);
  padding: 0.875rem;
  color: var(--iconPrimary);
  opacity: 0;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.s-formats__item:hover {
  border-color: var(--borderPrimary);
  background-color: var(--bgSecondary);
}

.s-formats__item:hover .s-formats__item-wrapper-title-subtitle {
  background-color: var(--commonBlack);
}

.s-formats__item:hover .s-formats__item-title {
  color: var(--commonWhite);
}

.s-formats__item:hover .s-formats__item-subtitle {
  color: var(--commonWhite);
  opacity: 1;
}

.s-formats__item:hover .s-formats__item-image--default {
  opacity: 0;
}

.s-formats__item:hover .s-formats__item-image--hover {
  opacity: 1;
}

.s-formats__item:hover .s-formats__item-icon {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 79.9988em) {
  .s-formats__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 61.9988em) {
  .s-formats__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 47.9988em) {
  .s-formats__title {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: 0;
  }

  .s-formats__wrapper--btn-text {
    margin-bottom: 4rem;
    gap: 1rem;
  }

  .s-formats__btn {
    height: 4rem;
    width: 4rem;
  }

  .s-formats__text {
    max-width: 100%;
    font-size: 0.625rem;
    line-height: 0.875rem;
  }

  .s-formats__item__link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .s-formats__item-text {
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0;
  }

  .s-formats__item-icon {
    padding: 0.5rem;
  }
}

@media (max-width: 23.4375em) {
  .s-formats__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (prefers-color-scheme: dark) {
  .s-formats {
    --bgSecondary: var(--surfaceNeutralD2Default);
    --iconPrimary: var(--textNeutralForceLight);
    --borderPrimary: var(--borderNeutralSubtlest);
    --red: var(--textAccentPrimaryDefault);
  }
}